button {
  &:focus {
    outline: none;
  }
}

.btn {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  min-height: 38px;
  @include transition(all, 0.5s, ease);

  a {
    color: inherit;
  }

  &:hover {
    a {
    }
  }

  &:focus {
    box-shadow: none !important;
    outline: none;

    a {
    }
  }

  &:disabled,
  &.disabled {
    background: $gray-600;

    a {
    }
  }

  .loader-wrapper {
    position: relative;
    padding-left: 0px;
    @include transition(all, 0.3s, ease);

    &.loading {
      padding-left: 25px;

      .spinner-border {
        visibility: visible;
        opacity: 1;
        letter-spacing: 0px;
        left: 0px;
      }
    }

    .spinner-border {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 10px;
      visibility: hidden;
      opacity: 0;
      @include transition(all, 0.5s, ease);
    }
  }

  .icon {
    &.icon-prefix {
      margin-right: 10px;
    }
  }

  .btn-text {
    position: relative;
  }
}

.btn-primary {
  background: $primary;
  color: white;

  @include media-breakpoint-down(sm) {
    //&.btn-small{}
    width: 100%;
  }
  &:hover,
  &:focus,
  &:active {
    background: $primary-dark;
    color: white;

    a {
      color: white;
    }
  }
}

.btn-secondary {
  &:hover,
  &:focus,
  &:active {
  }
}

.btn-full-width {
  width: 100%;
}

.btn-small {
  font-size: 12px;
  min-height: unset;
}

.btn-large {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.default-btn-action-wrapper {
  @extend .display-flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;

  .btn {
    margin-right: 5px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.button-arrow-up{
  display: none;
  place-items: center;
  position: fixed;
  background-color: $primary;
  padding: 11px 13px 12px 13px;
  border-radius: 50%;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  cursor: pointer;
  color: white;
  &.show{
    display: grid;
  }

  .icon{
    font-size: 1.25rem;
  }
}