// Main Component
.mr-4{
  margin-right: 1rem;
}

.mr-2{
  margin-right: 0.5rem;
}

.mb-unset{
  margin-bottom: unset !important;
}

.fs-5{
  font-size: 1.25rem;
}

.print-text-note{
  font-size: 1.5rem;
  color: #000;
}

.card-paper-shadow {
  background: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
}

.price-warning{
  margin-left: 5px;
  color: $danger;
}

.table-report{
  tbody{
    td{
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .table-idr{
    padding-right: 40px;
    font-weight: bold;
  }

  .table-head{
    color: $main-bg;
  }
  .title-table{
    font-weight: bold;
  }

  .data-table{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.pad{
      padding-left: 40px;
    }
  }

}

#report-section{
  .card-header {
    .card-header-filter-wrapper {
      @extend .display-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 100%;
    }
  }
}


.card-paper {
  @extend .card-paper-shadow;

  .card-header {
    @extend .display-flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border-radius: unset;
    padding: 10px 20px;
    min-height: 40px;

    .card-header-title {
      margin-bottom: 0px;
      font-weight: 600;
    }
  }

  .card-content {
    padding: 20px;

    &.type-button {
      padding: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    .card-content {
      padding: 15px;
    }
  }
}

.card-empty-content {
  text-align: center;
  font-weight: 700;
  color: $danger;
}

.default-filter-section {
  @extend .display-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;

  .filter-item {
    margin-right: 15px;
    margin-bottom: 15px;
    flex: 20% 0;
    &.type-search-full{
      margin-right: 0;
      flex: 100% 0;
    }
    &.filter-special {
      flex: 30% 0;

      &.type-search {
        flex: 50% 0;
      }
    }

    @include media-breakpoint-down(md) {
      flex: 100%;
      &.filter-special {
        flex: 100%;

        &.type-search {
          flex: 100%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }
}

.vui-progress-label-component {
  margin-bottom: 15px;
  font-size: 12px;

  .title {
    margin-bottom: 5px;
  }

  .vui-progress-bar {
    height: 10px;
    margin-bottom: 5px;

    .progress-bar {
      background: $primary;
    }
  }
}

.page-header-component {
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm){
    flex-direction: column;
    align-items: flex-start;

    .title{
      margin-bottom: 10px;
    }

    .btn-wrapper{
      width: 100%;
      .btn-primary{
        width: 100%;
      }
    }
  }

  .action-wrapper {
    @extend .display-flex;
    align-items: center;

    .action-item {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }
    }

    @include media-breakpoint-down(sm){
      flex-direction: column;
      width: 100%;
      gap : 10px;
      .action-item {
        margin-right: 0px;
        width: 100%;
      }
    }
  }
}

.card-files-section {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;

  &:focus {
    outline: none;
  }

  &.single-file {
    grid-template-columns: minmax(0, 200px);

    .card-file {
      .card-inner {
        .card-filled-wrapper {
          .action-wrapper {
            min-height: 40px;
          }

          .icon-delete-file {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.select-media {
    grid-template-columns: repeat(4, 1fr);
  }

  &.type-center {
    justify-content: center;
  }

  .card-file {
    border: 1px dashed $gray-500;
    cursor: pointer;
    outline: none;
    background: transparent;
    @include transition(all, 0.5s, ease);
    border-radius: $border-radius;

    &:hover {
      background: $gray-200;
    }

    &.filled {
      border: none;
      background: $gray-200;
    }

    &.select {
      border: 3px solid transparent;

      &:hover {
        border-color: $primary;
      }
    }

    .card-inner {
      @extend .display-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      &:focus {
        outline: none;
      }

      .card-label {
        text-align: center;
        margin-bottom: 0px;
      }

      .card-filled-wrapper {
        width: 100%;
        height: 100%;
        @extend .display-flex;
        overflow: hidden;
        position: relative;
        align-items: center;
        border-radius: $border-radius;
        justify-content: center;

        .filled-icon {
          font-size: 32px;
        }

        .image {
          height: 100%;
          max-width: 100%;
          object-fit: cover;
        }

        .info-wrapper {
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          background: rgba($primary, 0.5);
          padding: 15px;
          opacity: 0;
          @include transition(all, 0.5s, ease);

          &:hover {
            opacity: 1;
          }

          .info-size {
            font-size: 8px;
            margin-bottom: 5px;
          }

          .info-name {
            text-align: center;
            width: 100%;
            @include ellipsis(1);
          }
        }

        .action-wrapper {
          position: absolute;
          top: 0px;
          width: 100%;
          min-height: 30px;

          .icon-delete-file {
            color: $danger;
            position: absolute;
            right: 5%;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
          }

          .action-check {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5%;
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.card-upload-wrapper {
  .upload-dropzone {
    @extend .display-flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    margin-bottom: 15px;
    min-height: 200px;
    border: 1px dashed $gray-500;
    border-radius: $border-radius;
    cursor: pointer;
    outline: none;
    background: transparent;
    @include transition(all, 0.5s, ease);

    &:hover {
      background: $gray-200;
    }

    .icon {
      font-size: 86px;
      margin-right: 15px;
    }

    .title {
      font-weight: 700;
      margin-bottom: 0px;
    }

    .summary {
      margin-bottom: 0px;
    }
  }
}

.button-submit-print-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: auto;
  align-items: center;

  .title {
    font-size: 16px;
  }

  .button-wrapper {
    display: grid;
    grid-template-columns: 115px 115px 115px;
    grid-gap: 20px;
    grid-auto-rows: auto;
    align-items: center;

    .btn {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;

    .button-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        width: 100%;
      }

      .btn {
        width: 100%;
      }
    }
  }
}

.card-setting-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-auto-rows: auto;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }
}

.card-setting {
  background: white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow;
  padding: 30px;

  .inner-content {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    align-items: center;

    .icon {
      &.type-setting {
        height: 50px;
        width: 50px;
      }
    }

    .title {
      font-size: 18.16px;
      margin-bottom: 0px;
      font-weight: 600;
    }
  }
}

.card-action-form {
  @extend .card-paper-shadow;
  @extend .display-flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  margin-top: 20px;

  .title {
    flex: 1;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .btn-action-wrapper {
    @extend .display-flex;
    grid-gap: 15px;

    .btn {
      min-width: 150px;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding: 25px 15px;

    .title {
      margin-bottom: 15px;
    }

    .btn-action-wrapper {
      flex-direction: column;
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }
}

.card-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  .card-summary-title {
    label {
      display: block;
      font-weight: 600;
    }
  }

  .card-summary-content {
    label {
      display: block;
    }
  }
}

.table-summary {
  color: $text-color;
  table-layout: fixed;
  width: 100%;

  &.type-right {
    text-align: right;

    .form-control {
      text-align: right;
    }

    tr {
      td {
        &:first-child {
          width: 40%;
        }
      }
    }
  }

  tr {
    td {
      padding: 5px 15px;

      &:first-child {
        font-weight: 700;
        padding-left: 0px;
      }

      &:last-of-type {
        padding-right: 0px;
      }
    }
  }
}

.loader-overlay {
  @extend .display-flex;
  align-items: center;
  justify-content: center;
}

.table-btn-wrapper {
  .btn {
    white-space: nowrap;
  }
}
// End Main Component
