#purchase-section {
  .page-header-component {
    @include media-breakpoint-down(md) {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      grid-gap: 20px;
    }

    .button-custom-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      grid-gap: 20px;

      .btn {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
