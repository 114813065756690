// Default Page Layout
.default-page-layout {
  display: grid;
  grid-template-columns: 8fr 4fr;
  grid-gap: 20px;
  grid-auto-rows: auto;

  &.layout-reverse {
    grid-template-columns: 4fr 8fr;
  }

  &.layout-full{
    grid-template-columns: 1fr;
  }

  .layout-content {
    display: grid;
    grid-gap: 20px;
    grid-auto-rows: auto;
  }

  .floating-content {
    position: sticky;
    position: -webkit-sticky;
    top: 80px;
  }

  &.type-profile {
    .card-files-section {
      &.single-file {
        justify-content: center;
      }
    }
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;

    &.layout-reverse {
      grid-template-columns: 1fr;
    }

    &.type-profile {
      display: flex;
      flex-direction: column;
    }
  }
}

.multiple-paper-section {
  .card-paper {
    margin-bottom: 20px;
  }
}
