#finance-section {
  .nav-item {
    flex: unset;
  }
}

#expense-section {
  .page-layout {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    grid-auto-rows: auto;

    .product-list-section {
      table {
        th {
          &:first-child {
            width: 10%;
          }
          &:last-child {
            width: 20%;
          }
        }
      }

      .form-btn-wrapper {
        justify-content: flex-start;
      }
    }

    .row {
      &.type-bill {
        flex-direction: row-reverse;
      }

      .bill-section {
        align-items: flex-end;
        .content-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 25px;
          grid-auto-rows: auto;
          .title {
            h5 {
              font-size: 14px;
              line-height: 2em;

              &:last-child {
                margin-top: 7%;
              }
            }
          }
          .content {
            .payment-text {
              border: 1px solid black;
              padding: 2px;
            }
            h5 {
              font-size: 14px;
              text-align: left;
              line-height: 2em;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
    }
  }
}
