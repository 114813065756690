#contact-section {
  .card-paper {
    .card-content {
      .react-bootstrap-table table {
        table-layout: auto !important;
      }

      .form-btn-wrapper {
        justify-content: flex-start;
        @include media-breakpoint-down(md) {
          margin-top: 10px;
        }
      }
    }
  }

  .button-icon-wrapper {
    border-radius: 50%;
    width: 10px;
    min-height: unset;
  }

  .table-pic-custom {

    th {
      &:first-child {
        padding: unset;
      }
    }

    td {
      &:first-child {
        padding: unset;
      }
    }

    .table-icon-wrapper {
      padding: 0.5rem 0.5rem !important;
    }
  }

  .table-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn {

    }
  }

  .table-button-custom {
    width: 100%;
    margin-right: 5px;
  }

}
