#sales-order-page {
  .page-header-component {
    @include media-breakpoint-down(md) {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      grid-gap: 20px;
    }

    .button-custom-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;
      grid-gap: 20px;

      .btn {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .page-layout {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    grid-auto-rows: auto;

    .layout-content {
      display: grid;
      grid-gap: 20px;
      grid-auto-rows: auto;
    }

    .floating-content {
      position: sticky;
      position: -webkit-sticky;
      top: 80px;
    }

    .right-form-section {
      .product-list-section {
        table {
          th {
            &:first-child {
              width: 10%;
            }
            &:last-child {
              width: 20%;
            }
          }
        }

        .form-btn-wrapper {
          justify-content: flex-start;
        }
      }

      .previous-balance-section {
        table {
          th {
            &:first-child {
              width: auto;
            }
            &:last-child {
              width: 20%;
            }
          }
        }
      }

      .membership-section {
        .content-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 25px;
          grid-auto-rows: auto;
          .title {
            h5 {
              font-size: 14px;
              line-height: 2em;
            }
          }
          .content {
            h5 {
              font-size: 14px;
              line-height: 2em;
            }
          }
        }
      }

      .bill-section {
        .content-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 25px;
          grid-auto-rows: auto;
          .title {
            h5 {
              font-size: 14px;
              line-height: 2em;

              &:last-child {
                margin-top: 7%;
              }
            }
          }
          .content {
            .payment-text {
              border: 1px solid black;
              padding: 2px;
            }
            h5 {
              font-size: 14px;
              text-align: left;
              line-height: 2em;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;

      .information-section {
        .form-wrapper {
          .col-md-12 {
            .form-group {
              .row {
                .col-md-4 {
                  .form-btn-wrapper {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#return-item-page {
  .page-layout {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    grid-auto-rows: auto;

    .product-list-section {
      table {
        th {
          &:first-child {
            width: 10%;
          }

          &:last-child {
            width: 20%;
          }
        }
      }

      .form-btn-wrapper {
        justify-content: flex-start;
      }
    }

    .membership-section {
      .content-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;
        grid-auto-rows: auto;
        .title {
          h5 {
            font-size: 14px;
            line-height: 2em;
          }
        }
        .content {
          h5 {
            font-size: 14px;
            line-height: 2em;
          }
        }
      }
    }

    .row {
      &.type-bill {
        flex-direction: row-reverse;
      }

      .bill-section {
        align-items: flex-end;
        .content-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 25px;
          grid-auto-rows: auto;
          .title {
            h5 {
              font-size: 14px;
              line-height: 2em;

              &:last-child {
                margin-top: 7%;
              }
            }
          }
          .content {
            .payment-text {
              border: 1px solid black;
              padding: 2px;
            }
            h5 {
              font-size: 14px;
              text-align: left;
              line-height: 2em;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;

      .information-section {
        .form-wrapper {
          .col-md-12 {
            .form-group {
              .row {
                .col-md-4 {
                  .form-btn-wrapper {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .card-paper {
    .card-content {
      &.type-button {
        padding: 15px;
        .row {
          justify-content: flex-end;
          .col-md-6 {
            .title {
              padding-top: 2%;
              padding-left: 2%;
              font-size: 16px;
            }

            .btn {
              width: 100%;
            }

            @include media-breakpoint-down(lg) {
              margin-top: 10px;
            }
          }

          .col-md-4 {
            @include media-breakpoint-down(lg) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
