@page {
    width: 100%;
    margin: 10px;
}

.print-component {

  table {
    color: black;
    font-size: 22px;

    td {
      vertical-align: top;
    }
  }

  .table-header {
    width: 100%;

    td {
      text-align: center;
    }
  }

  .table-list {
    table-layout: auto;
    width: 100%;
    text-align: center;

    th {
      white-space: nowrap;
    }

    th, td {
      padding: 5px 10px;
      width: 1px;
    }

    td {
      word-break: break-word;
    }

    thead {
      text-align: center;
    }
  }

  .table-summary {
    width: 100%;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .no-wrap {
    white-space: nowrap !important;
    word-break: unset !important;
  }
}




