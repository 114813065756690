.daterangepicker {
  &.show-calendar {
    .drp-buttons {
      @extend .display-flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &.auto-apply {
    .drp-buttons {
      display: none;
    }
  }

  .drp-buttons .btn {
    min-height: unset;
  }

  td {
    &.active {
      background: $primary;

      &:hover {
        background: $primary;
      }
    }
  }
}

.table {
  .table-btn-wrapper {
    @extend .display-flex;
    align-items: center;
    justify-content: center;

    &.long-table{
      @media screen and (max-width: 1200px){
        align-items: unset;
        flex-direction: column;
        gap: 5px;
      }
    }

    @include media-breakpoint-down(md){
      align-items: unset;
      flex-direction: column;
      gap: 5px;
    }

    .btn {
      margin-right: 5px;
    }
  }

  .table-slot {

    .table-error-icon {
      margin-left: 5px;
    }
  }

  .table-error {
    color: $danger;
  }
}

.table-add-item {
  td {
    vertical-align: sub;
  }

  .close-action {
    width: 25px;
  }
}

.table-fixed {
  table-layout: fixed;
}

.react-bootstrap-table {
  .table {
    td {
      word-break: break-all;
    }

    @include media-breakpoint-down(sm){
      td{
        word-break: unset;
      }
    }

    .dropup {
      .caret {
        content: '';
        border-top: 0;
        border-bottom: 4px dashed;
      }
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }

  .table-loading-section {
    height: 250px;
    @extend .display-flex;
    align-items: center;
    justify-content: center;

    &.for-infinite-scroll{
      height: unset;
    }
  }
}

.react-bootstrap-table-pagination {
  .dropdown {
    display: inline-flex;

    .btn {
      min-height: unset;
      margin-right: 10px;
      background: transparent;
      color: $text-color;
      border: 1px solid $border-color;
    }

    .dropdown-menu {
      background: white;

      .dropdown-item {
        padding: 0px;

        a {
          display: block;
          padding: 0.25rem 1rem;
          background: white;
          @include transition(all, 0.5s, ease);

          &:hover {
            background: $gray-200;
          }
        }
      }
    }
  }

  .pagination {
    justify-content: flex-end;

    .page-item {
      &.active {
        .page-link {
          background: $primary;
          color: white;
        }
      }

      .page-link {
        color: $primary;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.tox-tinymce-aux {
  z-index: 1040 !important;
}
