.react-bootstrap-table{
  &.table-infinite-scroll{
    position: relative;

    .table-header{
      th.has-sorting{
        cursor: pointer;
      }
    }

    &.virtualize{
      //overflow: hidden;
      max-height: 400px;
      overflow: auto;
    }
    //.table-header{
    //  position: sticky;
    //  top: 0;
    //  background-color: #ededef;
    //  color: $table-data-color;
    //  th{
    //    width: 10rem;
    //    border-bottom-color: inherit;
    //    text-transform: uppercase;
    //    &.sticky-position{
    //      background-color: #ededef;
    //    }
    //  }
    //}
    th, td{
      border-width: 1px;
      border-bottom-color: inherit !important;
      &.sticky-position{
        position: sticky;
        right: 0;
      }
    }
  }
}