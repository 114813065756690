#product-section {
  .default-page-layout {
    .table {
      th {
        border-bottom: transparent;
      }

      td {
        border-bottom: transparent;
      }

      .table-form-dropdown {
        width: 150px;
      }

      .text-table {
        .text-custom {
          margin-top: 10px;
          text-align: center;
        }
      }

      .form-table {
        @include media-breakpoint-down(lg) {
          width: 100px;
        }
      }
      @include media-breakpoint-down(lg) {
        width: 600px;
      }
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;

      .card-files-section {
        grid-template-columns: repeat(2, 1fr);
        .card-file {
          height: 130px !important;
        }
      }
    }
  }

  .table-price-wrapper {
    overflow: auto;
    display: grid;

    .table-price {
      overflow: auto;
      white-space: nowrap;
      display: block;
    }

    th {
      display: inline-block;
      width: 130px;
    }

    td {
      display: inline-block;
      width: 130px;
    }
  }
}
