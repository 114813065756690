#dashboard-section {
  @keyframes load {
    from {
      left: -100%;
    }
    to   {
      left: 100%;
    }
  }

  .is-table-loading{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 300px;
    cursor: progress;
    background: #eee;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -100%;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, transparent 0%, #f9f9f9 50%, transparent 100%);
      animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
  }

  .card-header {

    .card-header-filter-wrapper {
      @extend .display-flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      width: 100%;

      .title-wrapper {

      }

      .filter-item {

      }
    }
  }

  .card-content {
    .box-dashboard-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .box-caption-wrapper {
        font-size: 32px;
        font-weight: 700;
        width: 100%;

        .box-caption {
          font-size: 24px;
        }
      }

      canvas{
        height: 350px !important;
        width: 100% !important;
      }

      &.is-loading-chart{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 350px;
        cursor: progress;
        background: #eee;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: -100%;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(to right, transparent 0%, #f9f9f9 50%, transparent 100%);
          animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
        }
      }

      &.is-loading-box{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 60px;
        cursor: progress;
        background: #eee;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: -100%;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(to right, transparent 0%, #f9f9f9 50%, transparent 100%);
          animation: load 2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
        }
      }

      .box-icon-wrapper {
        text-align: right;
        font-size: 27.34px;
      }
    }
  }


  .grid-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    grid-auto-rows: auto;

    &.one-section {
      grid-template-columns: 1fr;
    }

    &.three-section {
      grid-template-columns: repeat(3, 1fr);
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }


  .total-section-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }

  .sales-order-section-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }

  .top-five-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    grid-auto-rows: auto;
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }
}
