#cash-monitoring-section {
  .default-page-layout {
    grid-template-columns: 4fr 8fr;
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;

      .amount-text {
        margin-bottom: 25px;
      }
    }
  }
}
