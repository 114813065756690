.modal-image-wrapper {
  max-height: 50vh;
  min-height: 50vh;
  overflow-y: scroll;
  padding-right: 15px;
}

.modal-upload-wrapper {
  min-height: 60vh;

  .card-files-section {
    max-height: 32vh;
    min-height: 32vh;
    overflow-y: scroll;
    padding-right: 15px;
  }

  .btn-action-wrapper {
    margin-bottom: 15px;
  }
}

.modal-aciton-wrapper {
  text-align: center;

  p {
    margin-bottom: 0px;
  }

  .title {
    font-weight: 700;
  }
}
